exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-case-jsx": () => import("./../../../src/pages/case.jsx" /* webpackChunkName: "component---src-pages-case-jsx" */),
  "component---src-pages-how-to-use-jsx": () => import("./../../../src/pages/how-to-use.jsx" /* webpackChunkName: "component---src-pages-how-to-use-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-news-jsx": () => import("./../../../src/pages/news.jsx" /* webpackChunkName: "component---src-pages-news-jsx" */),
  "component---src-pages-our-mission-jsx": () => import("./../../../src/pages/our-mission.jsx" /* webpackChunkName: "component---src-pages-our-mission-jsx" */),
  "component---src-pages-search-jsx": () => import("./../../../src/pages/search.jsx" /* webpackChunkName: "component---src-pages-search-jsx" */)
}

